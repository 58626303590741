<template>
    <div>
        <client-only>
            <div v-if="!cookiesAccepted" id="cookies" class="show">
                <div class="container">
                    <div class="row">
                        <div class="col-md-2">
                            <router-link to="/" class="logo">
                                <img src="@/esf_weert_mobilesupplies/assets/static/images/logo.png"
                                    class="bg-transparent" alt="Mobile Supplies Logo" width="150" height="26" />
                            </router-link>
                        </div>
                        <div class="col-md-10">
                            <h6>{{ $t('cookies_heading') }}</h6>
                            <p class="mb-1">{{ $t('cookies_content') }}</p>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="acceptCookies">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import ClientOnly from "vue-client-only";

export default {
    components: {
        ClientOnly
    },
    data() {
        return {
            cookiesAccepted: false
        }
    },
    methods: {
        acceptCookies() {
            this.$cookies.set('cookiesAccepted', 'true', '7d'); // Cookie ko 7 din ke liye set kar rahe hain
            this.cookiesAccepted = true;
        },
    },
    mounted() {
        this.cookiesAccepted = this.$cookies.isKey('cookiesAccepted');
    }
}
</script>