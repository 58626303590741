<template>
    <nav class="main-nav">
        <ul class="menu sf-arrows">
            <li class="megamenu-container" :class="{ active: current == '/' }">
                <router-link to="/">{{ $t('home') }}</router-link>
            </li>
            <li v-for="(item, i) in menu" :key="i" class="megamenu-container"
                :class="{ active: current == item.url_path }">
                <router-link :to="'/' + item.url_path + '/'">{{ $t((item.name).toLowerCase()) }}</router-link>
                <div class="megamenu megamenu-md" v-if="item.children.length">
                    <div class="menu-col">
                        <div class="menu-title">Menu</div>
                        <ul>
                            <li v-for="(child, i) in item.children" :key="i"
                                :class="{ active: $route.path.includes(child.url_path) }">
                                <router-link :to="'/' + child.url_path" class="px-2">
                                    <span>
                                        {{ child.name }}
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    computed: {
        menu() {
            return this.$store.getters["menu/getMenu"];
        },
        current: function () {
            const path = this.$route.path;
            const isHome = this.$route.path === '/';
            // if (this.$route.path.includes('merken')) return 'merken';
            if (path.includes('telefoon')) return 'telefoon';
            if (path.includes('tablet')) return 'tablet';
            if (path.includes('camera')) return 'camera';
            if (path.includes('gaming')) return 'gaming';
            if (isHome) return '/';
            return '';
        }
    }
};
</script>